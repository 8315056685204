



































import { defineComponent, ref } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'Signup',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const auth = useAuth();
    const notification = useNotification();
    
    const step = ref(1);
    const email = ref('');
    const password = ref('');
    const phoneNumber = ref('');
    const phoneAuthCode = ref('');
    const isSending = ref(false);

    auth.verifyEmailCode(props.code).then(result => {
      email.value = result.data.email;
      step.value = 2;
    }).catch(error => {
      notification.error(error.message || error);
    });

    const nextStep = () => {
      if (!password.value.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$/)) {
        notification.error('大文字、小文字、数字を含む8文字以上の半角英数字・記号で設定してください');
        return;
      }
      step.value = 3;
    }

    const prevStep = () => {
      step.value = 2;
    }

    const sendPhoneAuthCode = async () => {
      isSending.value = true;
      await auth.savePhonenumber(phoneNumber.value);
      isSending.value = false;
      step.value = 4;
    }

    const resendPhoneAuthCode = async () => {
      await auth.savePhonenumber(phoneNumber.value);
      notification.notify('認証コードを再送しました')
    }

    const signup = async () => {
      isSending.value = true;
      await auth.signup({
        email: email.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        emailAuthCode: props.code,
        phoneAuthCode: phoneAuthCode.value,
      });
      isSending.value = false;
      notification.notify('登録完了');
      context.root.$router.replace('/');
    }

    return { step, password, phoneNumber, phoneAuthCode, isSending, nextStep, prevStep, sendPhoneAuthCode, resendPhoneAuthCode, signup };
  },
});
